ion-content ion-toolbar {
  --background: translucent;
}

.productContainer {
  text-align: center;
}

.cartIcon {
  float: right;
  margin-right: 3px;
  margin-top: 0px;
}
