.container {
    max-width: calc(50% - 10px);
    min-width: calc(50% - 10px);
    margin: 5px;
    display: initial;
    background: white;
    box-shadow: 0px 2px 2px #00000033;
    padding: 14px 10px;

}
.header_img_wrapper{
    width: 100%;
    background: transparent;
    position: 'relative';
    box-shadow: none;
    padding: 0;
    margin: 0;
}
.header_img{
    margin: 16px auto;
    width: 40%;
    position: relative;
    left: 0;
    right: 0;
}
.header_img img{
    width: 40%;
}
.products_wrapper{
    display: flex;
    flex-wrap: wrap;
    background: transparent;
    width: 100%;
    padding: 0 8px;
    margin: 0;
    box-shadow: none;
}


.button {
    --border-radius: 8px;
    font-size: 17px;
    margin-top: 4px;
    margin-bottom: 0;
    padding: 7px 0;
}
.button .button-native{
    color: black;
    font-weight: 300;
}

.productName {
  font-size: 12px;
  text-align: left;
  margin: 0px;
}

.productImg {
  height: 140px;
  width: 150px;
}

.priceSize {
  font-size: 20px;
}
.product_button {

}
.product_button.added{
    color: white;
}
.product_button.added .button-native{
    --background: black;
    --background-focused: #000000;
    --background-hover: black;
    --background-activated: #000000;
}

.price{
    font-size: 16px;
    font-weight: bold;
}