ion-content ion-toolbar {
  --background: translucent;
}

.qrIcon {
  float: right;
  margin-right: 3px;
  margin-top: 0px;
}
.cover_page{
    background-color: #F6F7F9;
}
.home_btn{
    width: 100%;
    height: 40px;
    border-radius: 22px;
    --border-radius: 22px;
    background-color: #24282C;
    color: white;
    --background-activated: #24282C;
    --background-focused: #24282C;
    --background-hover: #24282C;
    --background: #24282C;
    --background-activated-opacity: 1;
    --background-focused-opacity: 1;
    --background-hover-opacity: 1;
}

.cover_img{
    width: 100%;
    margin-left: 0;
    margin-top: 0;
}

.cover_input{
    background-color: white;
    border: 1px solid #DDDDDD;
}

#background-content{
    background-color: #F6F7F9;
}

.button_wrapper{
    position: absolute;
    bottom: 14%;
    margin: 0 5%;
    width: 90%;
    --background: none;
    border: 0;
    box-shadow: none;
}
.cover_input{
    border-radius: 4px;
    --margin-bottom: 14px;
    height: 40px;
    --padding-left: 14px;
    --padding-right: 14px;
}

.cover_input .native-input.sc-ion-input-ios{
    padding-left: 14px;
    padding-right: 14px;
    font-size: 14px;
}