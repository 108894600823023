.card {
  margin: 0 15px 0 15px;
}

.loginButton {
  margin-top: 30px;
}

.loginForm {
    color: black;
}