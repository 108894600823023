.cartImg {
  height: 70px;
  width: 70px;
}

.deleteIcon {
  float: right;
}

.textColumn {
  text-align: center;
  margin: auto;
}

.totalPrice {
  float: right;
  padding-left: 190px;
}
