ion-content ion-toolbar {
  --background: translucent;
}

.container {
  text-align: center;
}

.logoutButton {
  margin-top: 30px;
}

.card {
  margin: 10px 15px 15px 15px;
}

.cartImg {
  height: 70px;
  width: 70px;
  border-radius: 20%;
}

.textColumn {
  text-align: center;
  margin: auto;
}

.ios.hydrated, .md.hydrated{
    color: black;
}